<template>
  <div class="event">
    <div class="container-fluid event-banner">
      <img :src="webinar.image" alt="" />
    </div>
    <div class="container-fluid cta-strip">
      <div class="container">
        <div class="date-pop">
          {{ webinar.date }}
        </div>
        <div class="row">
          <div class="col-md-8">
            <h3>{{ webinar.title }}</h3>
            <span>Webinar</span>
          </div>
          <div class="col-md-4" v-if="this.webinar.is_buyable == true">
            <div class="price-box">
              <h3>₹{{ webinar.price }}</h3>
              <router-link
                class="btn cta-primary"
                :to="{
                  name: 'webinarCheckout',
                  params: { slug: webinar.slug }
                }"
                >Book Now</router-link
              >
            </div>
          </div>
          <div class="col-md-4 price-box" v-else>
            <div>
              <span>This Event has already started.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container content-wrapper">
      <h4>About</h4>
      <p v-html="webinar.description"></p>
    </div>
  </div>
</template>

<script>
import WebinarService from "@/services/WebinarService.js";
import errorLog from "@/errorLog";
export default {
  name: "WebinarDetails",
  created() {
    this.getWebinar();
  },
  data() {
    return {
      slug: this.$route.params.slug,
      isWebinarLoader: true,
      webinar: []
    };
  },
  methods: {
    async getWebinar() {
      this.isWebinarLoader = true;
      await WebinarService.getWebinar({ slug: this.slug })
        .then(result => {
          this.webinar = result.data;
        })
        .catch(error => {
          this.isWebinarLoader = false;
          errorLog.log(error);
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/style/event.scss";
</style>
